import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Trip from "../../services/api/tripRequestApi";
import PageLayout from "../../components/layouts/PageLayout";
import TabNavigation from "../../components/reusable/TripRequestTabNavigation";
import DateUtils from "../../services/utility/DateUtils";
import NodalPointModal from "./NodalPointModal"; // Add this import

export default function TripManagement() {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [tripList, setTripList] = useState([]);
  const [tripStatus, setTripStatus] = useState(0);
  const [tripCount, setTripCount] = useState([]);
  const [nodalPointModel, setNodalPointModel] = useState(false);
  const [tripType, setTripType] = useState("");

  useEffect(() => {
    fetchTripData();
    fetchTripCount();
  }, [fromDate, toDate, tripStatus, tripType]);

  const fetchTripCount = async () => {
    try {
      const userId = localStorage.getItem("userIdFleetrax");

      const dataObj = {
        userId: userId,
      };
      const data = await Trip.getCountOfTripStatus(dataObj);
      setTripCount(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchTripData = async () => {
    try {
      const userId = localStorage.getItem("userIdFleetrax");

      const dataObj = {
        fromDate: fromDate,
        toDate: toDate,
        status: tripStatus,
        tripType: tripType.id,
        userId: userId,
      };

      const data = await Trip.getTrips(dataObj);
      setTripList(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  return (
    <>
      <PageLayout
        title={"Trip Request Management"}
        description={"View your trip details here."}
        paddingRight={true}
        buttonTitle={"Add Nodal Point"}
        setAddTripModal={setNodalPointModel}
      >
        <div className="w-full flex mt-7">
          <TabNavigation
            setFromDate={setFromDate}
            setToDate={setToDate}
            fromDate={fromDate}
            toDate={toDate}
            tripList={tripList}
            fetchTripData={fetchTripData}
            fetchTripCount={fetchTripCount}
            setTripStatus={setTripStatus}
            tripCount={tripCount}
            tripStatus={tripStatus}
            setTripType={setTripType}
            tripType={tripType}
          />
        </div>
      </PageLayout>

      <NodalPointModal
        isVisible={nodalPointModel}
        onClose={() => setNodalPointModel(false)}
      />
    </>
  );
}
