import React from 'react';
import { FileSpreadsheet, Download } from 'lucide-react';
import { downloadTripDataAsExcel } from './downloadTripDataAsExcel';

const DownloadButton = ({ tripList }) => {
  const handleDownload = () => {
    downloadTripDataAsExcel(tripList);
  };

  return (
    <button
      onClick={handleDownload}
      className="group flex items-center gap-2 bg-green-600 hover:bg-green-700 text-white font-medium py-2.5 px-4 rounded-lg w-48 justify-center transition-all duration-200 shadow-sm hover:shadow-md"
    >
      <div className="flex items-center gap-2">
        <FileSpreadsheet size={18} className="text-white" />
        <span>Download Excel</span>
      </div>
      <Download 
        size={16} 
        className="opacity-0 group-hover:opacity-100 transform translate-y-0 group-hover:translate-y-0.5 transition-all duration-200" 
      />
    </button>
  );
};

export default DownloadButton;