import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import OTPVerify from "./pages/auth/OTPVerify";
import ResetPassword from "./pages/auth/ResetPassword";
import Home from "./pages/home/Home";

import NavbarLayout from "./components/layouts/NavbarLayout";
import BlankLayout from "./components/layouts/BlankLayout";
import TripManagement from "./pages/trip-management/TripManagement";
import TripRequest from "./pages/trip-request/TripManagement";
import TripDetailsPage from "./pages/trip-management/TripDetailsPage";
import EmployeeManagement from "./pages/employee-management/EmployeeManagement";
import LiveTracking from "./pages/live-tracking/LiveTracking";
import DriverManagement from "./pages/driver-management/DriverManagement";
import DriverDetailsPage from "./pages/driver-management/DriverDetailsPage";
import EmployeeDetailsPage from "./pages/employee-management/EmployeeDetailsPage";
import ReportPage from "./pages/report/ReportPage";
import AccountsDetailsTable from "./components/tables/report-page/AccountDetailsTable";
import TicketManagement from "./pages/ticket-management/TicketManagement";
import TicketDetailsPage from "./pages/ticket-management/TicketDetailsPage";
import EmployeeDetailModal from "./pages/employee-management/EmployeeDetailModal";
import ExpensePage from "./pages/expense/ExpensePage";
import AdminPage from "./pages/admin/AdminPage";
import InvoicePage from "./pages/invoice/InvoicePage";
import PassbookPage from "./pages/passbook/PassbookPage";
import OutStation from "./pages/outstation/OutStation";
import PageLayout from "./components/layouts/PageLayout";
import InvoiceDetailsPage from "./pages/invoice/InvoiceDetailsPage";
import Remittance from "./pages/remittance/Remittance";
import RemittanceDetails from "./pages/remittance/RemittanceDetails";
import TripPaymentDetails from "./components/tables/remittance/TripPaymetnDetails";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import OutStationInvoice from "./pages/outstation/OutStationInvoice";
import RoutesPage from "./pages/routes/RoutesPage";
import RoutesDetailPage from "./pages/routes/RoutesDetailPage";
import LiveMap from "./components/tables/live-tracking/LiveMap";

export default function App() {
  const [employeeData, setEmployeeData] = useState({});

  return (
    <>
      <Routes>
        <Route path="/" element={<NavbarLayout />}>
          <Route path="home" element={<Home />} />
          <Route path="trip-management" element={<TripManagement />} />
          <Route path="trip-request" element={<TripRequest />} />
          <Route path="trip-management/:id" element={<TripDetailsPage />} />
          <Route
            path="employee-management"
            element={<EmployeeManagement setEmployeeData={setEmployeeData} />}
          />
          <Route
            path="employee-management/:id"
            element={<EmployeeDetailsPage employeeData={employeeData} />}
          />
          <Route path="live-tracking" element={<LiveTracking />} />
          <Route path="driver-management" element={<DriverManagement />} />
          <Route path="driver-management/:id" element={<DriverDetailsPage />} />
          <Route path="report" element={<ReportPage />} />
          <Route
            path="report/accounts-details"
            element={<AccountsDetailsTable />}
          />
          <Route path="ticket-management" element={<TicketManagement />} />
          <Route path="ticket-management/:id" element={<TicketDetailsPage />} />
          <Route path="admin" element={<AdminPage />} />
          <Route path="expenses" element={<ExpensePage />} />
          <Route path="passbook" element={<PassbookPage />} />
          <Route path="passbook/:id" element={<PassbookPage />} />
          <Route path="invoice" element={<InvoicePage />} />
          <Route path="invoice/invoice-details" element={<InvoiceDetailsPage />} />
          <Route path="outstation" element={<OutStation />} />
          <Route path="outstation/:id" element={<OutStationInvoice />} />
          <Route path="settings" element={<PageLayout />} />
          <Route path='remittance' element={<Remittance />} />
          <Route path='remittance/:id' element={<RemittanceDetails />} />
          <Route path='trip-payment-details' element={<TripPaymentDetails />} />
          <Route path="routes" element={<RoutesPage />} />
          <Route path="routes/:id" element={<RoutesDetailPage />} />
        </Route>

          <Route path="live-map/:id" element={<LiveMap />} />
        <Route path="/" element={<BlankLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="employeedetails/:id" element={<EmployeeDetailModal />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="verify-otp" element={<OTPVerify />} />
          <Route path="reset-password" element={<ResetPassword />} />

        </Route>
      </Routes>
    </>
  );
}
