import React, { useEffect, useState, useCallback, useRef } from "react";
import { Modal, Button, Spin } from "antd";
import {
  CompressOutlined,
  PlusOutlined,
  MinusOutlined,
  AimOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import io from "socket.io-client";
import Common from "../../../services/api/commonApi";
import DateUtils from "../../../services/utility/DateUtils";

const LiveMapPopup = ({ visible, onClose, routeId, driverId }) => {
  const [driverLocation, setDriverLocation] = useState({
    lat: 13.067439,
    lng: 80.237617,
  });
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState(null);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const mapRef = useRef(null);
  const socketRef = useRef(null);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const defaultMapOptions = {
    zoom: 19,
    center: driverLocation,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
  };

  const fetchDriverLocation = async () => {
    try {
      setIsRefreshing(true);
      const response = await Common.getDriverLocation(driverId);
      console.log("Driver location response:", response);
      const { latitude, longitude } = response;
      setDriverLocation({ lat: latitude, lng: longitude });
      setLastFetchTime(new Date());
      setLoading(false);
    } catch (error) {
      console.error("Error fetching driver location:", error);
    } finally {
      setIsRefreshing(false);
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    fetchDriverLocation();
  };

  useEffect(() => {
    if (!visible) return;
  
    console.log("Setting up socket for routeId:", routeId);
    fetchDriverLocation();
  
    const socketInstance = io("https://api.fleetrax.techhivetechnology.com", {
      transports: ["websocket"],
      withCredentials: false,
    });
  
    socketRef.current = socketInstance;
    socketInstance.emit("joinRoom", routeId);
  
    // Single locationUpdate listener
    socketInstance.on("locationUpdate", (data) => {
      console.log("Received location update:", data);
      setDriverLocation({ lat: data.latitude, lng: data.longitude });
    });
  
    return () => {
      if (socketInstance) {
        console.log("Disconnecting socket");
        socketInstance.disconnect();
      }
    };
  }, [visible, routeId, driverId]);

  const onMapLoad = useCallback((mapInstance) => {
    mapRef.current = mapInstance;
    setMap(mapInstance);
  }, []);

  const handleZoomIn = () => {
    if (mapRef.current) {
      mapRef.current.setZoom(mapRef.current.getZoom() + 1);
    }
  };

  const handleZoomOut = () => {
    if (mapRef.current) {
      mapRef.current.setZoom(mapRef.current.getZoom() - 1);
    }
  };

  const handleCenter = () => {
    if (mapRef.current) {
      mapRef.current.panTo(driverLocation);
      mapRef.current.setZoom(15);
    }
  };

  const formatLastUpdateTime = () => {
    if (!lastFetchTime) return "Never";
    // Convert to IST and format
    return DateUtils.getCurrentDateTime();
  };

  return (
    <Modal
      title={
        <div className="flex justify-between items-center">
          <span>Live Vehicle Tracking</span>
          <div className="flex items-center gap-4 mr-8">
            <span className="text-sm text-gray-500">
              Last Updated: {formatLastUpdateTime()}
            </span>
            <Button
              icon={<ReloadOutlined spin={isRefreshing} />}
              onClick={handleRefresh}
              disabled={isRefreshing}
            >
              Refresh
            </Button>
          </div>
        </div>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
      centered
    >
      <div className="relative" style={{ height: "400px" }}>
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <Spin size="large" />
          </div>
        ) : (
          <LoadScript
            googleMapsApiKey="AIzaSyCZr5giYgXzXjvOSYfA_LndEkBg6LVuQIc"
            libraries={["places"]}
          >
            <div className="relative">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                options={{
                  ...defaultMapOptions,
                  center: driverLocation,
                }}
                onLoad={onMapLoad}
              >
                {map && (
                  <Marker position={driverLocation} title="Vehicle Location" />
                )}
              </GoogleMap>

              {/* Custom Controls */}
              <div className="absolute right-4 top-4 flex flex-col gap-2">
                <Button
                  icon={<PlusOutlined />}
                  onClick={handleZoomIn}
                  className="bg-white"
                />
                <Button
                  icon={<MinusOutlined />}
                  onClick={handleZoomOut}
                  className="bg-white"
                />
                <Button
                  icon={<AimOutlined />}
                  onClick={handleCenter}
                  className="bg-white"
                />
              </div>

              {/* Location Info */}
              <div className="absolute left-4 bottom-4 bg-white p-2 rounded shadow">
                <div className="font-medium">Vehicle Location</div>
                <div className="text-sm text-gray-500">
                  Lat: {driverLocation.lat.toFixed(4)}
                  <br />
                  Lng: {driverLocation.lng.toFixed(4)}
                </div>
              </div>
            </div>
          </LoadScript>
        )}
      </div>
    </Modal>
  );
};

export default LiveMapPopup;
