import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { CompressOutlined } from "@ant-design/icons";
import { Button } from "antd";
import io from "socket.io-client";
import Common from "../../../services/api/commonApi";

const LiveMap = () => {
  const { id } = useParams();
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const socketRef = useRef(null);

  // State to store driver location
  const [driverLocation, setDriverLocation] = useState({
    lat: 28.6139,
    lng: 77.209,
  });

  const mapContainerStyle = {
    width: "100%",
    height: "500px",
  };

  const defaultMapOptions = {
    zoom: 5,
    center: driverLocation,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  useEffect(() => {
    console.log("Navigated ID:", id);
    fetchDriverLocation();

    const socketInstance = io("https://api.fleetrax.techhivetechnology.com", {
      transports: ["websocket"],
      withCredentials: false,
    });
    socketRef.current = socketInstance;
    socketInstance.emit("joinRoom", id);
    socketInstance.on("liveTrackingUpdates", () => {
      console.log("Receiving live updates in live tracking screen ⚡");
      fetchDriverLocation();
    });
    // Cleanup socket on component unmount
    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, [id]);

  const fetchDriverLocation = async () => {
    try {
      const driverId = 979;
      const response = await Common.getDriverLocation(driverId);
      console.log("response------->>>>>>>", response);
      const { latitude, longitude } = response;
  
      // Ensure correct format for Google Maps API
      setDriverLocation({ lat: latitude, lng: longitude });
    } catch (error) {
      console.error("error while fetching driver location", error);
    }
  };
  
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    setMap(map);
  }, []);

  // Function to focus on the marker
  const handleFocusMarker = useCallback(() => {
    if (mapRef.current) {
      // Center the map on the marker
      mapRef.current.panTo(driverLocation);

      // Zoom in closer to the marker
      mapRef.current.setZoom(30);
    }
  }, [driverLocation]);

  return (
    <div className="p-4 relative">
      <h1 className="text-black text-2xl mb-4">Live Map</h1>
      <p className="text-black mb-4">Map for ID: {id}</p>

      <LoadScript
        googleMapsApiKey="AIzaSyCZr5giYgXzXjvOSYfA_LndEkBg6LVuQIc"
        libraries={["places"]}
      >
        <div className="relative">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            options={{
              ...defaultMapOptions,
              center: driverLocation,
            }}
            onLoad={onMapLoad}
          >
            {map && (
              <Marker position={driverLocation} title="Vehicle Location" />
            )}
          </GoogleMap>

          {/* Focus Button */}
          <Button
            icon={<CompressOutlined />}
            onClick={handleFocusMarker}
            className="absolute top-4 right-4 z-10"
            type="primary"
          >
            Focus Marker
          </Button>
        </div>
      </LoadScript>
    </div>
  );
};

export default LiveMap;
