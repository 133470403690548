import * as XLSX from 'xlsx';

export const downloadTripDataAsExcel = (tripList) => {
  const { rows, merges } = formatTripData(tripList);

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(rows);

  // Set column widths
  ws['!cols'] = [
    { wch: 8 },   // Sl.No
    { wch: 20 },  // Driver Name
    { wch: 15 },  // Cab Number
    { wch: 12 },  // Mobile num
    { wch: 25 },  // Emp Name
    { wch: 12 },  // PH Num
    { wch: 20 },  // Area
    { wch: 12 },  // Pickup Time
  ];

  // Add merge ranges
  ws['!merges'] = merges;

  // Apply styles to all cells
  const range = XLSX.utils.decode_range(ws['!ref']);

  for (let R = range.s.r; R <= range.e.r; ++R) {
    const isHeaderRow = R === 0;
    const isDriverRow = ws[XLSX.utils.encode_cell({ r: R, c: 0 })]?.v?.toString().startsWith('Cab');

    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellRef = XLSX.utils.encode_cell({ r: R, c: C });

      if (!ws[cellRef]) {
        ws[cellRef] = { v: '' };
      }

      // Base style for all cells
      const cellStyle = {
        alignment: { vertical: 'center', horizontal: 'center' },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
        font: { name: 'Arial', sz: 10 },
      };

      // Apply header styles
      if (isHeaderRow) {
        cellStyle.fill = { fgColor: { rgb: 'FFFF00' } }; // Yellow header
        cellStyle.font = { ...cellStyle.font, bold: true, sz: 11 };
      }

      // Apply driver row styles
      if (isDriverRow) {
        cellStyle.fill = { fgColor: { rgb: 'FFFF99' } }; // Light yellow for driver rows
      }

      // Apply employee row styles
      if (!isHeaderRow && !isDriverRow) {
        cellStyle.fill = { fgColor: { rgb: 'FFFFCC' } }; // Pale yellow for employee rows
      }

      ws[cellRef].s = cellStyle;
    }
  }

  // Add worksheet to workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Trip Data');

  // Generate Excel file
  XLSX.writeFile(wb, `Trip_Data_${new Date().toISOString().split('T')[0]}.xlsx`);
};

const formatTripData = (tripList) => {
  const headers = ['Sl.No', 'Driver Name', 'Cab Number', 'Mobile num', 'Emp Name', 'PH Num', 'Area', 'Pickup Time'];
  const rows = [headers];
  const merges = [];

  let currentRow = 1;
  let cabCounter = 1;

  tripList.forEach((trip, tripIndex) => {
    const employeeCount = trip.liveTrackingData.length;
    if (employeeCount === 0) return;

    // Add merge ranges for repeated driver info
    if (employeeCount > 1) {
      merges.push({
        s: { r: currentRow, c: 0 },
        e: { r: currentRow + employeeCount - 1, c: 0 },
      });
      merges.push({
        s: { r: currentRow, c: 1 },
        e: { r: currentRow + employeeCount - 1, c: 1 },
      });
      merges.push({
        s: { r: currentRow, c: 2 },
        e: { r: currentRow + employeeCount - 1, c: 2 },
      });
      merges.push({
        s: { r: currentRow, c: 3 },
        e: { r: currentRow + employeeCount - 1, c: 3 },
      });
    }

    // Add employee rows
    trip.liveTrackingData.forEach((employee, index) => {
      const row = [
        index === 0 ? `Cab ${cabCounter}` : '',
        index === 0 ? trip.driverName : '',
        index === 0 ? trip.vehicleNo : '',
        index === 0 ? trip.driverNumber : '',
        employee.employee.employeeName,
        employee.employee.mobileNumber,
        employee.pickupLocation,
        employee.pickupTime, // Added pickup time
      ];
      rows.push(row);
      currentRow++;
    });

    // Add empty row after each driver's group (except the last one)
    if (tripIndex < tripList.length - 1) {
      rows.push(Array(8).fill('')); // Updated to match new column count
      currentRow++;
    }

    cabCounter++;
  });

  return { rows, merges };
};